<template>
	<div>
		<div class="column is-full">
			<div class="card events-card is-size-6">
				<header class="card-header">
					<p class="card-header-title">
					Direcciones
					</p>
					<a href="#" class="card-header-icon" aria-label="more options">
						<span class="icon">
							<i class="fa fa-angle-down" aria-hidden="true"></i>
						</span>
					</a>
				</header>
				<div class="card-table">
					<div class="content">
						<table class="table is-fullwidth is-striped is-size-7">
							<thead v-if="addresses.length > 0">
								<th></th>
								<th>Nombre</th>
								<th>Dirección</th>
								<th>Comuna</th>
								<th></th>
							</thead>
							<tbody>
								<tr v-if="addresses.length === 0">
									<td width="5%"><i class="fa fa-bell-o"></i></td>
									<td>Sin dirección</td>
								</tr>
								<tr v-for="localAddress in addresses" :key="localAddress.id">
									<td width="5%"><i class="fa fa-bell-o"></i></td>
									<td>{{ localAddress.name }}</td>
									<td>{{ localAddress.address }}</td>
									<td>{{ localAddress.comuna_name }}</td>
									<td class="level-right">
										<div class="buttons">
											<a class="button is-small is-primary is-light is-info" @click.prevent="getAddress(localAddress.id)">
												<span class="icon">
													<i class="fa fa-edit"></i>
												</span>
											</a>
											<a v-if="hasWritePermission" class="button is-small is-primary is-light is-danger has-text-left" @click.prevent="deleteAddress(localAddress.id)">
												<span class="icon">
													<i class="fa fa-trash"></i>
												</span>
											</a>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<footer class="card-footer">
					<a v-if="hasWritePermission" @click.prevent="eventModal()" class="card-footer-item button is-primary is-light">
						<span class="icon is-small">
							<i class="fa fa-plus-square"></i>
						</span>
						<span>Nuevo</span>
					</a>
				</footer>
			</div>
		</div>
		<!-- MODAL -->
		<div class="modal" id="modal">
			<div class="modal-background"></div>
			<div class="modal-card">
				<header class="modal-card-head">
					<p class="modal-card-title">Agregar dirección</p>
					<button class="delete"  @click.prevent="eventModal()" aria-label="close"></button>
				</header>
				<section class="modal-card-body">
					<div class="field">
						<label class="label" for="#name">Nombre:</label>
						<input
							v-model="tmpAddress.name"
							class="input"
							type="text"
							id="address"
							placeholder="Nombre"
						>
					</div>
					<div class="field">
						<label class="label" for="#name">Dirección:</label>
						<input
							v-model="tmpAddress.address"
							class="input"
							type="text"
							id="address"
							placeholder="Dirección"
						>
					</div>
					<div class="field">
						<select
							v-model="tmpAddress.comuna_id"
							class="input"
							id="comuna"
						>
							<option value="0" disabled>Seleccione una comuna</option>
							<option v-for="comuna in comunas" :key="comuna.id" :value="comuna.id">
								{{ comuna.name }}
							</option>
						</select>
					</div>
    
				</section>
				<footer class="modal-card-foot">
					<button v-if="hasWritePermission" class="button is-success" @click.prevent="addAddress()">Agregar</button>
					<button v-if="hasWritePermission" class="button" @click.prevent="cancelModal()">Cancelar</button>
					<button v-else class="button" @click.prevent="cancelModal()">Cerrar</button>
				</footer>
			</div>
		</div>
	</div>
</template>
<script>
export default {
  data: () => ({
    addresses: [],
    error: false,
    strError: '',
    tmpAddress: { id: '', name: '', address: '', company_id: '', comuna_id: 0 },
		comunas: []
  }),
  props: {
		company: Object,
		hasWritePermission: Boolean
  },
  methods: {
		cancelModal () {
			this.tmpAddress.id = ''
			this.tmpAddress.name = ''
			this.tmpAddress.address = ''
			this.tmpAddress.comuna_id = 0
			this.eventModal()
		},
    eventModal () {
      var Modal = document.querySelector(`#modal`)
      Modal.classList.toggle('is-active')
    },
    addAddress () {
			this.tmpAddress.company_id = this.company.id
			this.$axios
			.post('company/createAddress', { address: this.tmpAddress })
			.then(response => {
				const result = response.data.result
				if (result === 'OK'){
					this.tmpAddress.id = ''
					this.tmpAddress.name = ''
					this.tmpAddress.address = ''
					this.tmpAddress.comuna_id = 0
					this.updateList()
					this.eventModal()
				}
			})
			.catch(error => console.log(error))
    },
		deleteAddress (addressId) {
			this.tmpAddress.id = addressId
			this.$axios
			.post('company/deleteAddress', { address: this.tmpAddress })
			.then(response => {
				const result = response.data.result
				if (result === 'OK') {
					this.updateList()
				} else {
					alert('Ha ocurrido un error')
				}
			})
			.catch(error => console.log(error))
    },
		getAddress (addressId) {
			this.$axios
			.get('company/getAddress', { params: { address_id: addressId } })
			.then(response => {
				this.tmpAddress = response.data.address
				this.eventModal()
			})
		},
		async updateList() {
			// console.log('updateList()')
			this.$axios
			.get('company/getAddresses', { params: { company_id: this.company.id } })
			.then(response => {
				// console.log(response.data.secrets)
				this.addresses = response.data.addresses
			})
			.catch(error => console.log(error))
    },
		async getComunas(){
			this.$axios
			.get('comunas/getComunas')
			.then(response => {
				// console.log(response.data.secrets)
				// this.addresses = response.data.addresses
				this.comunas = response.data.comunas
			})
			.catch(error => console.log(error))
		}
  },
	watch: {
		company: function(newVal) { // watch it
			this.company = newVal
			this.getComunas()
			this.updateList()
		}
	},
	computed: {
		fullRut: {
			get: function() {
				return this.company.rut + '-' + this.company.dv
			},
			set: function (newValue) {
				const local_rut = newValue.replaceAll('.', '').replaceAll('-', '').slice(0, -1)
				const local_dv = newValue.slice(-1)
				// console.log('rut: ' + local_rut)
				// console.log('dv: ' + local_dv)
				this.company.rut = local_rut
				this.company.dv = local_dv
			}
		}
	}
}
</script>