<template>
  <div class="container">    
    <CompanyShow :company="this.company" :recordProp='this.record' :hasWritePermission="hasPermission('EDITFORM')"/>
    <Invoices v-if="false" :invoicesProp="invoices" @update-invoices="updateInvoices" :companyId="parseInt(company.id)" :company="this.company" :hasWritePermission="hasPermission('EDITFORM')"/>
    <ContactList :company="this.company" :hasWritePermission="hasPermission('EDITFORM')" />
    <ContractList v-if="false" :company="this.company" :currencies="this.currencies" :hasWritePermission="hasPermission('EDITFORM')"/>
    <SecretList :company="this.company" :hasWritePermission="hasPermission('EDITFORM')"/>
    <AddressList :company="this.company" :hasWritePermission="hasPermission('EDITFORM')"/>
    <PartnerList :company="this.company" :hasWritePermission="hasPermission('EDITFORM')"/>
    <LegalRepresentativeList :company="this.company" :hasWritePermission="hasPermission('EDITFORM')"/>
  </div>
</template>
<script>
import CompanyShow from '@/components/company/CompanyShow.vue'
import AddressList from '@/components/company/AddressList.vue'
import PartnerList from '@/components/company/PartnerList.vue'
import SecretList from '@/components/company/CompanySecrets.vue'
import LegalRepresentativeList from '@/components/company/LegalRepresentativeList.vue'
import ContractList from '@/components/company/CompanyContractList.vue'
import ContactList from '@/components/company/ContactList.vue'
import Invoices from '@/components/company/Invoices.vue'
import auth from '@/auth/index'

export default {
  name: 'CompanyView',
  components: {
    CompanyShow,
    AddressList,
    PartnerList,
    SecretList,
    LegalRepresentativeList,
    ContractList,
    ContactList,
    Invoices
  },
  data: () => ({
    company: { 
      id: 0
      },
    record: {
      id: 0
    },
    invoices: [],
    currencies: []
  }),
  methods: {
    async getCompany() {
      const companyId = parseInt(this.$route.params.id)
      this.company.id = parseInt(companyId)
      await this.$axios
      .get('company/getCompany', { params: { company: this.company } })
      .then(response => {
        this.company = response.data.company
        this.record = response.data.record
        this.invoices = response.data.invoices
      })
      .catch(error => console.log(error))
    },
    updateInvoices(){
      this.company = { id: 0 }
      this.getCompany()
    },
    async getCurrencies() {
      const companyId = parseInt(this.$route.params.id)
      this.company.id = parseInt(companyId)
      await this.$axios
      .get('company/getCurrencies')
      .then(response => {
        this.currencies = response.data.currencies
      })
      .catch(error => console.log(error))
    },
    hasPermission(code) {
      const result = auth.hasUserPermission(code)
      return result
    }
  },
  mounted() {
    this.getCurrencies()
    this.getCompany()
    
  }
}
</script>