<template>
	<div>
		<header class="line has-background-primary"></header>
		<section class="profile section">
			<div class="container">
				<div class="columns">
					<div class="column is-two-fifths">
						<h1 class="title is-size-1">{{ company.name }} <button v-if="hasWritePermission" @click.prevent="eventModalEditName()" class="button is-primary is-light is-small">
									<span class="icon is-small">
										<i class="fa fa-edit"></i>
									</span>
								</button></h1>
						<p class="subtitle">{{ fullRut }}</p>
					</div>
					<div class="column is-2">
						<figure class="image image is-128x128">
							<img alt="Profile photo" class="is-rounded" src="https://freesvg.org/img/cliente.png">
						</figure>
					</div>
					<div class="column has-text-black-light has-text-right-in-desktop box is-size-7 is-5 is-desktop">
						<div class="columns is-2">
							<div class="column is-two-fifths has-text-weight-light has-text-justified has-text-weight-bold">
								<p>Código REMU </p>
							</div>
							<div class="column has-text-left">
								{{ record.id_remu? record.id_remu:'Sin Información' }}
							</div>
						</div>
						<div class="columns is-2 has-text-justified">
							<div class="column is-two-fifths has-text-weight-light has-text-weight-bold">
								Código CONTA
							</div>
							<div class="column has-text-left">
								{{ record.id_conta? record.id_conta:'Sin Información' }}
							</div>
						</div>

						<div class="columns is-two-fifths has-text-justified">
							<div class="column is-two-fifths has-text-weight-light has-text-weight-bold">
								Inicio de Actividades
							</div>
							<div class="column has-text-left">
								{{ record.start_activities? new Date(record.start_activities).toLocaleDateString():'Sin Información' }}
							</div>
						</div>

						<div class="columns has-text-justified">
							<div class="column is-two-fifths has-text-weight-light has-text-weight-bold">
								Régimen Tributario
							</div>
							<div class="column has-text-left">
								{{ record.tax_regime_id >0? record.tax_regime_name:'Sin Información'  }}
							</div>
						</div>

						<div class="columns has-text-justified">
							<div class="column is-two-fifths has-text-weight-light has-text-weight-bold">
								Tipo Facturador
							</div>
							<div class="column has-text-left">
								{{ record.biller_type_id >0? record.biller_type_name:'Sin Información' }}
							</div>
						</div>
						<div class="columns has-text-justified">
							<div class="column is-two-fifths has-text-weight-light has-text-weight-bold">
								Tipo Organizacional
							</div>
							<div class="column has-text-left">
								{{ record.organizational_type? record.organizational_type:'Sin Información' }}
							</div>
						</div>
						<div v-if="hasWritePermission" class="columns has-text-justified">
							<div class="column has-text-center">
								<router-link  :to="{ name: record.id? 'RecordEdit':'RecordNew', params: { company_id: this.company.id }}" class="button is-primary is-light is-small">
									<span class="icon is-small">
										<i class="fa fa-edit"></i>
									</span>
									<span>Modificar</span>
								</router-link>
							</div>
							<div class="column has-text-left">
								<a class="button is-danger is-light is-small" @click.prevent="eventModalDelete()">
									<span class="icon is-small">
										<i class="fa fa-trash"></i>
									</span>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<div class="modal" id="modalConfirmDelete">
			<div class="modal-background"></div>
			<div class="modal-card">
				<header class="modal-card-head">
				<p class="modal-card-title">Acción Destructiva</p>
				<button class="delete"  @click.prevent="eventModalDelete()" aria-label="close"></button>
				</header>
				<section class="modal-card-body">
					Confirmar si deseas eliminar el cliente
				</section>
				<footer class="modal-card-foot has-text-centered inline-block">
					<button class="button is-danger" @click.prevent="deleteCompany()">Eliminar</button>
					<button class="button" @click.prevent="eventModalDelete()">Cancel</button>
				</footer>
			</div>
		</div>
		<div class="modal" id="modalEditName">
			<div class="modal-background"></div>
			<div class="modal-card">
				<header class="modal-card-head">
					<p class="modal-card-title">Edita el nombre del cliente</p>
					<button class="delete"  @click.prevent="eventModalEditName()" aria-label="close"></button>
				</header>
				<section class="modal-card-body">
					
					<input class="input"
            type="text" v-model="company.name"/>
				</section>
				<footer class="modal-card-foot has-text-centered inline-block">
					<button class="button is-link" @click.prevent="updateCompany()" >Guardar</button>
					<button class="button" @click.prevent="clickCancelEditName()" >Cancelar</button>
				</footer>
			</div>
		</div>
	</div>
</template>
<script>

export default {
	components: {
		
	},
  data: () => ({ 
		record: {
			id_remu: '',
			id_conta: '',
			start_activities: new Date().toLocaleDateString(),
			tax_regime_id: -1, 
      biller_type_id: -1,
			tmpCompName: ''
		}
	}),
	props: {
		company: Object,
		recordProp: Object,
		hasWritePermission: Boolean
	},
	watch: {
		recordProp: function(newVal) {
			this.record = newVal
		}
	},
	computed: {
		fullRut: {
			get: function() {
				let val = this.company.rut? this.company.rut.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : ''
        return val + '-' + (this.company? this.company.dv : '')
			}
		}
	},
	methods: {
		eventModalDelete () {
      const Modal = document.querySelector(`#modalConfirmDelete`)
      Modal.classList.toggle('is-active')
    },
		eventModalEditName () {
      const Modal = document.querySelector(`#modalEditName`)
			this.tmpCompName = this.company.name
      Modal.classList.toggle('is-active')
    },
		clickCancelEditName () {
			this.company.name = this.tmpCompName
			this.eventModalEditName()
		},
		async deleteCompany () {
			await this.$axios
			.post('company/delete', { company: this.company })
			.then(response => {
				const result = response.data.result
				// console.log(result, 'company/delete')
				if (result) {
					this.$router.push('/')
				} else {
					alert('Ha ocurrido un error')
				}
			})
			.catch(error => console.error(error))
		},
		async updateCompany () {
			await this.$axios
			.post('company/update', { company: this.company })
			.then(response => {
				const result = response.data.result
				if (result) {
					this.eventModalEditName()
				}
				else {
					alert('Ha ocurrido un error')
				}
			})
			.catch(error => console.error(error))
		},
		

	}
	
}
</script>